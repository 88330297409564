var render = function () {
  var _vm$getSearchFlight, _vm$getSearchFlight$s;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "header-tab-flight-id"
    }
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.searchFlightArray, function (trip, tripIndex) {
    return _c('swiper-slide', {
      key: tripIndex,
      staticClass: "slide fit-content"
    }, [_c('BButton', {
      staticClass: "p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column",
      style: _vm.tabIndex === tripIndex ? "background: ".concat(_vm.headerColor, "; color: white") : "color: ".concat(_vm.headerColor, "; background: white; border: 1px solid ").concat(_vm.headerColor, ";"),
      attrs: {
        "variant": "vna-color"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTab(tripIndex);
        }
      }
    }, [_c('span', {
      staticClass: "fw-700 text-16px",
      attrs: {
        "id": "draftSelectedTrip"
      }
    }, [_vm._v(" #" + _vm._s(tripIndex + 1) + " " + _vm._s("".concat(trip.startPoint, "-").concat(trip.endPoint, " ").concat(_vm.convertISODateTime(trip.departDate).date)) + " ")]), _c('span', {
      staticClass: "text-14px font-weight-light"
    }, [_vm.draftSelectedTrip[tripIndex] ? _c('span', [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(_vm.draftSelectedTrip[tripIndex].airline, _vm.draftSelectedTrip[tripIndex].flightNumber))) + " " + _vm._s(_vm.convertISODateTime(_vm.draftSelectedTrip[tripIndex].departure.at, _vm.draftSelectedTrip[tripIndex].departure.timezone).time) + " - " + _vm._s(_vm.convertISODateTime(_vm.draftSelectedTrip[tripIndex].arrival.at, _vm.draftSelectedTrip[tripIndex].arrival.timezone).time) + " ")]) : _c('span', {
      staticClass: "text-warning"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.notSelectedFlights')) + " ")])])])], 1);
  }), _vm.getGroupedItineraryResponse && _vm.notDomesticFlight ? _c('swiper-slide', {
    staticClass: "slide fit-content"
  }, [_c('BButton', {
    staticClass: "p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column",
    style: _vm.tabIndex === _vm.searchFlightArray.length ? "background: ".concat(_vm.headerColor, "; color: white") : "color: ".concat(_vm.headerColor, "; background: white; border: 1px solid ").concat(_vm.headerColor, ";"),
    attrs: {
      "variant": "vna-color"
    },
    on: {
      "click": function click($event) {
        return _vm.handleClickTab(_vm.searchFlightArray.length);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center gap-1"
  }, [_c('span', {
    staticClass: "fw-700 text-16px text-warning d-flex-center flex-column"
  }, [_c('div', [_vm._v("GKH")]), _c('div', [_vm._v(_vm._s((_vm$getSearchFlight = _vm.getSearchFlight) !== null && _vm$getSearchFlight !== void 0 && (_vm$getSearchFlight$s = _vm$getSearchFlight.sources) !== null && _vm$getSearchFlight$s !== void 0 && _vm$getSearchFlight$s.includes('1G') ? '1G' : ''))])]), _c('div', {
    staticClass: "d-flex-center flex-column gap-1"
  }, _vm._l(_vm.searchFlightArray, function (trip, tripIndex) {
    return _c('span', {
      key: tripIndex
    }, [_vm._v(_vm._s("".concat(trip.startPoint, "-").concat(trip.endPoint, " ").concat(_vm.convertISODateTime(trip.departDate).date)))]);
  }), 0)])])], 1) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }